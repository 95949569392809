<script setup>
import { onMounted, onUnmounted } from "vue";
import $ from "jquery";
import LoginPopup from "@/components/v2/popup/LoginPopup.vue";
import RegisterPopup from "@/components/v2/popup/RegisterPopup.vue";

const openLoginPopup = (evt) => {
  evt.preventDefault();
  evt.stopPropagation();
  $(".shogun-login").toggleClass("active");
};

const openRegisterPopup = (evt) => {
  evt.preventDefault();
  evt.stopPropagation();
  $(".shogun-register").toggleClass("active");
};

const dismissHandler = () => {
  $(".shogun-login").removeClass("active");
  $(".shogun-register").removeClass("active");
};
onMounted(() => {
  window.addEventListener("click", dismissHandler);
});
onUnmounted(() => {
  window.removeEventListener("click", dismissHandler);
});
</script>

<template>
  <!-- Top Bar -->
  <div class="header-top">
    <div class="container-fluid pe-4">
      <div
        class="header-top--inner d-flex justify-content-between align-items-center flex-wrap"
      >
        <!-- Left Box -->
        <div class="left-box d-flex align-items-center">
          <ul class="info-list">
            <li>始まりました！</li>
          </ul>
        </div>

        <!-- Right Box -->
        <div class="right-box d-flex align-items-center">
          <!-- Social Box -->
          <div class="info-list">
            <ul class="m-0">
              <li>
                <button data-lity="#contact-modal" @click="openLoginPopup">
                  <span class="icon bi bi-arrow-right-square-fill"></span>
                  <span style="color: #d70006; font-weight: 700">
                    {{ $store.getters.langweb("fe.login") }}
                  </span>
                </button>
              </li>
              <li>
                <button data-lity="#contact-modal" @click="openRegisterPopup">
                  <span class="icon bi bi-arrow-right-square-fill"></span>
                  <span style="color: #d70006; font-weight: 700">
                    {{ $store.getters.langweb("fe.registers") }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoginPopup />
  <RegisterPopup />
</template>

<style scoped>
.header .info-list li button {
  position: relative;
  font-size: var(--fs-13);
  color: var(--color-white);
  padding-left: 20px;
}

.header .info-list li button .icon {
  position: absolute;
  left: 0px;
  color: var(--color-one);
}

.header .info-list li:last-child {
  margin: 0px;
}

.header .info-list li button:hover {
  color: var(--color-one);
}
</style>
