import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/components/v2/HomePage.vue";
import ChangeInfo_city from "@/components/v1/ChangeInfo.vue";
import ChangePass_city from "@/components/v1/ChangePass.vue";
import Cashin_city from "@/components/v1/Cashin.vue";
import Cashout_city from "@/components/v1/Cashout.vue";
import Notification_city from "@/components/v1/Notification.vue";
import NotificationDetail_city from "@/components/v1/NotificationDetail.vue";
import Popup_city from "@/components/v1/Popup.vue";
import Chat_city from "@/components/v1/ChatList.vue";
import Contact_city from "@/components/v1/SendChat.vue";
import Partner_city from "@/components/v1/Partner.vue";
import PointToAmount_city from "@/components/v1/PointToAmount.vue";
import Mini_Game from "@/components/v1/Mini_Game.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/change-infomation",
    name: "ChangeInfo_city",
    component: ChangeInfo_city,
  },
  {
    path: "/change-password",
    name: "ChangePass_city",
    component: ChangePass_city,
  },
  {
    path: "/cash-in",
    name: "Cashin_city",
    component: Cashin_city,
  },
  {
    path: "/cash-out",
    name: "Cashout_city",
    component: Cashout_city,
  },
  {
    path: "/notification",
    name: "Notification_city",
    component: Notification_city,
  },
  {
    path: "/notification-detail:id",
    name: "NotificationDetail_city",
    component: NotificationDetail_city,
  },
  {
    path: "/popup",
    name: "Popup_city",
    component: Popup_city,
  },
  {
    path: "/chat",
    name: "Chat_city",
    component: Chat_city,
  },
  {
    path: "/contact-us",
    name: "Contact_city",
    component: Contact_city,
  },
  {
    path: "/partner",
    name: "Partner_city",
    component: Partner_city,
  },
  {
    path: "/point-to-amount",
    name: "PointToAmount_city",
    component: PointToAmount_city,
  },
  {
    path: "/mini-game",
    name: "mini-game",
    component: Mini_Game,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: "active",
});

export default router;
