<script setup>
import { defineProps, computed, onBeforeMount, onUnmounted, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  id: String,
  title: String,
  subTitle: String,
  listName: String,
  type: Number,
});

const store = useStore();
const list = computed(() => store.state[props.listName]);

const onClickItem = (item) => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else if (item.maintenance === 1) {
    store.dispatch("showMaintenancePopup", true);
  } else {
    if (props.listName.startsWith("slot")) {
      store.dispatch("showItemGamePopup", { open: true, ...item });
    } else {
      store.dispatch("getGameProcess", {
        api: item.vender,
        code: item.code,
        type: props.type,
      });
    }
  }
};

const MIN_LIST = 18;
const maxList = ref(MIN_LIST);
const isSmallScreen = ref(false);
const backgroundImage =
  props.id === "casino-section"
    ? `url('/assets/img/background/bg-1.png')`
    : props.id === "slot-section"
    ? `url('/assets/img/background/bg-2.png')`
    : `url('/assets/img/background/bg-2.png')`;

const onClickMore = () => {
  if (maxList.value === MIN_LIST) {
    maxList.value = list.value.length;
  } else {
    maxList.value = MIN_LIST;
  }
};

function handleMediaQueryChange(event) {
  isSmallScreen.value = event.matches;
}

onBeforeMount(() => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  mediaQuery.addEventListener("change", handleMediaQueryChange);
  handleMediaQueryChange(mediaQuery);
});

onUnmounted(() => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  mediaQuery.removeEventListener("change", handleMediaQueryChange);
});
</script>

<template>
  <section
    class="wptb-service-one z-index-2 bg-image-2 position-relative p-0 pt-5"
    :style="{ backgroundImage }"
    :id="id"
  >
    <div class="wptb-item-layer wptb-item-layer-four slide-top-to-bottom">
      <img src="/assets/img/more/object4.png" alt="img" />
    </div>
    <div class="container">
      <div class="wptb-heading">
        <div class="wptb-item--inner">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
              <h6 class="wptb-item--subtitle">{{ subTitle }}</h6>
              <h1 class="wptb-item--title">
                {{ title }}
                <span>
                  <button
                    v-if="!isSmallScreen"
                    class="underline text-lg"
                    @click="onClickMore"
                  >
                    全て表示
                  </button>
                </span>
              </h1>
              <div class="wptb-item--divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div :class="isSmallScreen ? 'scrollable-container' : 'row'">
        <div
          :class="isSmallScreen ? 'item' : 'col-lg-2 col-sm-6'"
          v-for="(item, index) in isSmallScreen ? list : list.slice(0, maxList)"
          :key="index"
        >
          <div class="wptb-image-box2 wow fadeInLeft">
            <div class="wptb-item--inner">
              <div class="wptb-item--image">
                <img :src="item.image" alt="img" />
              </div>
              <div class="wptb-item--holder">
                <button
                  class="wptb-item--wrap-content"
                  @click="onClickItem(item)"
                >
                  <span class="wptb-item--icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53"
                      height="13"
                      viewBox="0 0 53 13"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1_28160)">
                        <path
                          d="M24.0596 0H16.9192L3.78125 12.5875H10.9217L24.0596 0Z"
                          fill="#D70006"
                        />
                        <path
                          d="M37.8565 0H30.7161L17.5781 12.5875H24.7186L37.8565 0Z"
                          fill="#D70006"
                        />
                        <path
                          d="M51.6495 0H44.5029L31.3711 12.5875H38.5054L51.6495 0Z"
                          fill="#D70006"
                        />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="53" height="12.5875" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span class="wptb-item--button">
                    <span class="btn-three">
                      <span class="btn-wrap">
                        <span class="text-first" style="margin-bottom: 20px">
                          PLAY NOW
                        </span>
                        <span class="text-second">
                          <i class="bi bi-arrow-right"></i>
                        </span>
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.btn-three .btn-wrap {
  font-weight: 800;
  color: white;
}

.btn-three .btn-wrap:hover {
  color: red;
}

.scrollable-container {
  width: 100%;
  overflow-x: auto; /* 가로 스크롤 활성화 */
  white-space: nowrap; /* 아이템들이 한 줄로 나열되도록 */
}

.item {
  display: inline-block; /* 아이템들이 한 줄로 나열되도록 */
}
</style>
