<script setup>
import { onBeforeMount, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const list = ref([]);

onBeforeMount(() => {
  if (proxy.token) {
    const token_user = window.localStorage.getItem("token");
    proxy.axios
      .get(proxy.api_listFaq, {
        headers: {
          Authorization: "Bearer " + token_user,
        },
      })
      .then((response) => {
        list.value = response.data.data;
      });
  } else {
    proxy.axios.get(proxy.api_listnotifilist, {}).then((response) => {
      list.value = response.data.data;
      //**test
      if (list.value.length === 0) {
        list.value.push({ title: "Test FAQ", answer: "Test FAQ Answer" });
      }
    });
  }
});
</script>

<template>
  <div class="col-lg-7 pe-5">
    <div class="wptb-heading">
      <div class="row">
        <div class="col-md-10">
          <div class="wptb-item--inner">
            <h6 class="wptb-item--subtitle">FREQUENTLY ASKED QUESTIONS</h6>
            <h2 class="wptb-item--title">
              {{ store.getters.langweb("fe.faq") || "FAQ" }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="wptb-accordion wptb-accordion1 wow fadeInUp">
      <div
        class="wptb--item active"
        v-for="(faq, index) in list.slice(0, 5)"
        :key="index"
      >
        <h6 class="wptb-item-title">
          <span>
            <span class="wptb-item--number">{{ index + 1 }}</span>
            {{ faq.title.substring(0, 25) }}
          </span>
          <i class="bi bi-chevron-down"></i>
        </h6>
        <div class="wptb-item--content">{{ faq.answer || "" }}</div>
      </div>
      <div class="wptb-item--button" v-if="false">
        <button
          class="btn"
          @click="store.dispatch('showHomePopup', { open: true, tab: 12 })"
        >
          <span class="btn-readmore--text">
            View More Faq’s <i class="bi bi-chevron-double-right"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
