export const onOpenSports = (store) => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else {
    store.dispatch("getGameProcess", {
      api: "nexus",
      code: "bti_sports",
      type: 1,
    });
  }
  window.$("body").removeClass("mr_menu_active");
};

export const onOpenCashIn = (store) => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else {
    store.dispatch("showHomePopup", { open: true, tab: 1 });
  }
  window.$("body").removeClass("mr_menu_active");
};

export const onOpenCashOut = (store) => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else {
    store.dispatch("showHomePopup", { open: true, tab: 3 });
  }
  window.$("body").removeClass("mr_menu_active");
};
