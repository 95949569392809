<script setup>
import { getCurrentInstance, ref } from "vue";

const { proxy } = getCurrentInstance();

const partner_code = ref("");
const name = ref("");
const username = ref("");
const password = ref("");
const password_confirm = ref("");
const number_cash = ref("");
const phone = ref("");
const name_bank = ref("");
const number_bank = ref("");
const user_bank = ref("");

const register = (e) => {
  e.preventDefault();
  proxy.axios
    .post(proxy.api_register, {
      partner_code: partner_code.value,
      name: name.value,
      username: username.value,
      password: password.value,
      password_confirm: password_confirm.value,
      number_cash: number_cash.value,
      phone: phone.value,
      name_bank: name_bank.value,
      number_bank: number_bank.value,
      user_bank: user_bank.value,
    })
    .then((res) => {
      if (res.data.success === true) {
        //**??: no data
        // window.localStorage.setItem("token", res.data.data.token);
        // window.localStorage.setItem("level", res.data.data.user.level);
        // window.localStorage.setItem("offline", res.data.data.user.offline);
        // let today = new Date();
        // let date =
        //   today.getFullYear() +
        //   "-" +
        //   (today.getMonth() + 1) +
        //   "-" +
        //   today.getDate();
        // let time =
        //   today.getHours() +
        //   ":" +
        //   today.getMinutes() +
        //   ":" +
        //   today.getSeconds();
        // let timestamp = date + " " + time;
        // window.localStorage.setItem("time_token", timestamp);
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      } else {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: res.data.errors,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 404) {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: e.response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
};
</script>

<template>
  <div class="wptb-header--cart mr-3" v-if="!$store.state.loggedIn">
    <div class="wptb-cart-box shogun-register">
      <div class="wptb-grand-total">
        <ul>
          <li>
            {{ $store.getters.langweb("fe.registers") }}
          </li>
          <li>
            <form method="post" name="LoginFrm" @submit="(e) => register(e)">
              <div class="wptb-form--inner">
                <div class="row">
                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="username"
                        v-model="username"
                        :placeholder="$store.getters.langweb('fe.username')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-6 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        name="password"
                        v-model="password"
                        :placeholder="$store.getters.langweb('fe.passwords')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-6 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        name="password_confirm"
                        v-model="password_confirm"
                        :placeholder="
                          $store.getters.langweb('fe.passwordconfirm')
                        "
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="number_cash"
                        v-model="number_cash"
                        :placeholder="$store.getters.langweb('fe.numbercash')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="phone"
                        v-model="phone"
                        :placeholder="$store.getters.langweb('fe.phone')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="name_bank"
                        v-model="name_bank"
                        :placeholder="$store.getters.langweb('fe.namebank')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="number_bank"
                        v-model="number_bank"
                        :placeholder="$store.getters.langweb('fe.numberbank')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="user_bank"
                        v-model="user_bank"
                        :placeholder="$store.getters.langweb('fe.userbank')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12">
                    <div class="wptb-item--button">
                      <button class="btn-two white" type="submit">
                        <span class="btn-wrap">
                          <span class="text-first">
                            {{ $store.getters.langweb("fe.registers") }}
                          </span>
                          <span class="text-second">
                            <i class="bi bi-plus"></i>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
        <div class="wptb-item--button" v-if="false">
          <a
            href="javascript:void(0)"
            class="btn-three"
            style="height: 60px; font-size: var(--fs-16)"
          >
            <div class="btn-wrap">
              <span class="text-first">{{
                $store.getters.langweb("fe.login")
              }}</span>
              <span class="text-second">{{
                $store.getters.langweb("fe.login")
              }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wptb-cart-box {
  width: 350px;
}
</style>
