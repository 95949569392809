<script setup>
import { defineProps, toRefs } from "vue";
import { onOpenSports } from "@/components/v2/header/common";

const props = defineProps({
  totalSlides: Number,
  index: Number,
  image: String,
  subtitle: String,
  titles: Array,
  buttonIcon: String,
  playUrl: String,
});

const { totalSlides, index, image, subtitle, titles, buttonIcon } =
  toRefs(props);
</script>

<template>
  <div
    class="swiper-slide"
    role="group"
    :aria-label="index + 1 + ' / ' + totalSlides"
    style="width: 1129px"
  >
    <div class="wptb-slider--item">
      <div
        class="wptb-slider--image"
        :style="{ backgroundImage: `url(${image})` }"
      ></div>
      <div class="container">
        <div class="wptb-slider--inner">
          <div class="row">
            <!-- Content Column -->
            <div class="col-xxl-7 col-lg-6 col-md-10 col-sm-12">
              <div class="wptb-heading">
                <div class="wptb-item--inner">
                  <h6 class="wptb-item--subtitle">{{ subtitle }}</h6>
                  <h1
                    v-for="(title, titleIndex) in titles"
                    :key="titleIndex"
                    class="wptb-item--title"
                  >
                    {{ title }}
                  </h1>
                  <div class="wptb-item--button">
                    <a
                      class="btn-two"
                      v-if="playUrl === '#sports'"
                      href="javascript:void(0)"
                      @click="onOpenSports($store)"
                    >
                      <div class="btn-wrap">
                        <span class="text-first"> Play NOW </span>
                        <span class="text-second">
                          <i :class="'bi ' + buttonIcon"></i>
                        </span>
                      </div>
                    </a>
                    <a class="btn-two" :href="playUrl" v-else>
                      <div class="btn-wrap">
                        <span class="text-first"> Play NOW </span>
                        <span class="text-second">
                          <i :class="'bi ' + buttonIcon"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wptb-item-layer wptb-item-layer-one">
            <img src="/assets/img/slider/layer-1.png" alt="Layer 1" />
          </div>
          <div class="wptb-item-layer wptb-item-layer-two">
            <img src="/assets/img/slider/layer-2.png" alt="Layer 2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
