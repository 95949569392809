<script setup>
import { onMounted } from "vue";
import SlideItem from "./SliderItem.vue";

onMounted(() => {
  const id = setInterval(() => {
    if (!window.Swiper) {
      return;
    }
    clearInterval(id);

    new window.Swiper(".swiper-container", {
      loop: true,
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }, 100);
});

const slides = [
  {
    image: "/assets/img/slider/1.jpg",
    subtitle: "SHOGUN LIVE CASINO",
    titles: ["ライブカジノ", "すべてのプレイヤ 一の満足を保証します"],
    buttonIcon: "bi-play",
    playUrl: "#casino-section",
  },
  {
    image: "/assets/img/slider/2.jpg",
    subtitle: "SHOGUN SLOT GAME",
    titles: ["ライブスロット", "すべてのプレイヤー 一の満足を保証します"],
    buttonIcon: "bi-plus",
    playUrl: "#slotgame-section",
  },
  {
    image: "/assets/img/slider/3.jpg",
    subtitle: "SHOGUN SPORTS",
    titles: ["ライブスポーツ", "すべてのプレイヤ 一の満足を保証します"],
    buttonIcon: "bi-plus",
    playUrl: "#sports",
  },
];
</script>

<template>
  <section class="wptb-slider p-0">
    <div
      class="swiper-container swiper-main-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-backface-hidden"
    >
      <!-- Swiper Slides -->
      <div
        class="swiper-wrapper"
        id="swiper-wrapper"
        aria-live="polite"
        style="height: 976px"
      >
        <SlideItem
          v-for="(slide, index) in slides"
          :key="index"
          :index="index"
          :image="slide.image"
          :subtitle="slide.subtitle"
          :titles="slide.titles"
          :button-icon="slide.buttonIcon"
          :play-url="slide.playUrl"
        />
      </div>

      <!-- Pagination Dots -->
      <div class="wptb-swiper-dots">
        <div
          class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"
        >
          <span
            v-for="(dot, index) in slides.length"
            :key="index"
            class="swiper-pagination-bullet"
            :class="{ 'swiper-pagination-bullet-active': index === 0 }"
            tabindex="0"
            role="button"
            :aria-label="'Go to slide ' + (index + 1)"
            :aria-current="index === 0 ? 'true' : null"
          ></span>
        </div>
      </div>
      <!-- Notification -->
      <span
        class="swiper-notification"
        aria-live="assertive"
        aria-atomic="true"
      ></span>
    </div>
  </section>
</template>

<style scoped></style>
