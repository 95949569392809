<script setup></script>

<template>
  <footer class="footer style1">
    <div class="footer-top">
      <div class="container">
        <div class="footer--inner">
          <div class="row">
            <div class="col-xl-3 mb-5 mb-xl-0 pe-5">
              <img src="/assets/img/logo_footer.png" alt="img" />
            </div>

            <div class="col-xl-8">
              <div class="row">
                <div class="col-md-4 col-sm-6 mb-5 mb-md-0">
                  <h5 class="widget-title">私たちのパートナー</h5>
                  <img
                    src="/assets/img/partner/partner_logos-set01.png"
                    alt="img"
                  />
                </div>

                <div class="col-md-4 col-sm-6 mb-5 mb-md-0">
                  <h5 class="widget-title">私たちのパートナー</h5>
                  <img
                    src="/assets/img/partner/partner_logos-set02.png"
                    alt="img"
                  />
                </div>

                <div class="col-md-4 col-sm-6 mb-5 mb-md-0">
                  <h5 class="widget-title">私たちのパートナー</h5>
                  <img
                    src="/assets/img/partner/partner_logos-set03.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Bottom Part -->
    <div class="footer-bottom">
      <div class="container">
        <div class="footer-bottom-inner">
          <div class="copyright">
            <p>
              &copy;Copyright 2024 <a href="javascript:void(0)">SHOGUN</a>. All
              rights reserved
            </p>
          </div>
          <div class="social-box">
            <ul>
              <li><a href="javascript:void(0)" class="bi bi-facebook"></a></li>
              <li><a href="javascript:void(0)" class="bi bi-instagram"></a></li>
              <li><a href="javascript:void(0)" class="bi bi-linkedin"></a></li>
            </ul>
          </div>
          <div class="footer-nav-bottom">
            <ul>
              <li><a href="javascript:void(0)">ライブカジノ</a></li>
              <li><a href="javascript:void(0)">スロットゲームノ</a></li>
              <li><a href="javascript:void(0)">ミニゲーム </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped></style>
