<template>
  <div v-if="mainHide == 1">
    <div v-if="$route.path == '/'">
      <HeaderMain />
      <router-view :key="$route.params" />
      <FooterMain />
    </div>
    <div v-else>
      <HeaderMain />
      <div class="page-content">
        <div class="route-content container">
          <router-view :key="$route.params" />
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
  <div class="bgr_maintenance" v-else>
    <div class="container maintenance">
      <img src="./assets/images/system.png" alt="" />
      <h1 class="error-title"><span>We’ll be back soon!</span></h1>
      <div v-html="message" class="text_maintenance"></div>
    </div>
  </div>
</template>
<script>
//css
import "./assets/css/all.min.css";
import "./assets/css/tailwind.css";

import HeaderMain from "./components/v2/header/HeaderMain.vue";
import FooterMain from "./components/v2/footer/FooterMain.vue";

export default {
  name: "App",
  data() {
    return {
      mainHide: 1,
      message: "",
    };
  },
  components: {
    HeaderMain,
    FooterMain,
  },
  created() {
    this.mainTenance();
    setInterval(this.mainTenance, 5000);
  },
  async mounted() {
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    }

    try {
      await loadScript("/assets/js/jquery-3.6.0.min.js");
      await loadScript("/assets/js/bootstrap.min.js");
      await loadScript("/plugins/wow/wow.min.js");
      await loadScript("/plugins/swiper/swiper-bundle.min.js");
      await loadScript("/plugins/odometer/appear.js");
      await loadScript("/plugins/fancybox/jquery.fancybox.min.js");
      await loadScript("/assets/js/theme.js");
      console.log("All third party scripts loaded");
    } catch (error) {
      console.error("Script loading failed:", error);
    }
  },
  methods: {
    mainTenance() {
      this.axios
        .get(this.api_Setting)
        .then((res) => {
          if (res.data.success === true) {
            this.mainHide = 1;
          } else {
            this.mainHide = 1;
          }
        })
        .catch((e) => {
          this.message = e.response.data.message;
          if (e.response.status == 503) {
            this.mainHide = 0;
          }
        });
    },
  },
};
</script>

<style></style>
