import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue3-cookies";
// import Popover from 'vue-js-popover'

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUserSecret,
  faPiggyBank,
  faVault,
  faBell,
  faCommentDots,
  faCaretDown,
  faUserTie,
  faUnlockAlt,
  faBarsStaggered,
  faCaretUp,
  faRightFromBracket,
  faWindowRestore,
  faCircleUser,
  faHandHoldingDollar,
  faMoneyBill,
  faMoneyBill1Wave,
  faReply,
  faHandshake,
  faGift,
  faPlay,
  faTrophy,
  faCrown,
  faTableList,
  faMoneyBillTransfer,
  faUserGear,
  faCircleInfo,
  faClover,
  faFireFlameCurved,
  faBomb,
  faTablet,
  faPuzzlePiece,
  faClockRotateLeft,
  faMagnifyingGlassPlus,
  faComments,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import VueMobileDetection from "vue-mobile-detection";

library.add(
  faUserSecret,
  faPiggyBank,
  faVault,
  faBell,
  faCommentDots,
  faCaretDown,
  faUserTie,
  faUnlockAlt,
  faBarsStaggered,
  faCaretUp,
  faRightFromBracket,
  faWindowRestore,
  faCircleUser,
  faHandHoldingDollar,
  faMoneyBill,
  faMoneyBill1Wave,
  faReply,
  faHandshake,
  faGift,
  faPlay,
  faTrophy,
  faCrown,
  faTableList,
  faMoneyBillTransfer,
  faUserGear,
  faCircleInfo,
  faClover,
  faFireFlameCurved,
  faBomb,
  faTablet,
  faPuzzlePiece,
  faClockRotateLeft,
  faMagnifyingGlassPlus,
  faComments,
  faEye
);

// createApp(App).mount('#app')
const app = createApp(App);
app.use(VueMobileDetection);
app.use(router);
app.use(VueAxios, axios);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueSweetalert2);
app.use(Paginate);
app.use(VueCookies);
// app.use(Popover)

const domain_web = "https://api.shogun7.com/api/";

export const globalVars = {
  api_login: domain_web + "login",
  api_register: domain_web + "register",
  api_logout: domain_web + "logout",
  api_infouser: domain_web + "info-user",
  api_changeinfo: domain_web + "update-user",
  api_changepass: domain_web + "change-password",
  api_lang: domain_web + "language",
  api_cashin: domain_web + "cash/credit",
  api_listcashin: domain_web + "cash/list-credit",
  api_cashout: domain_web + "cash/debit",
  api_listcashout: domain_web + "cash/list-debit",
  api_listpopup: domain_web + "list-popup",
  api_listnotifi: domain_web + "notifacation/list",
  api_listnotifilist: domain_web + "noticelist",
  api_listchat: domain_web + "chat/list",
  api_sendchat: domain_web + "chat/client-send",
  // api_listtitle: domain_web + 'chat/list-title-chat,
  api_listtitle: domain_web + "auto-chat/list",

  api_resettoken: domain_web + "refresh",
  api_reply: domain_web + "chat/client-reply",
  api_delete: domain_web + "chat/delete-chat",
  api_number: domain_web + "number-notifi",
  api_listuser: domain_web + "list-user",
  api_registerUser: domain_web + "create-user",
  api_listcashin_partner: domain_web + "number-cash",
  api_listGift: domain_web + "number-gift",
  api_listconvert: domain_web + "list-point",
  api_pointoamout: domain_web + "cash/point-to-amount?point=",
  api_gamecasino: domain_web + "list-game?refcode=0",
  api_gamecasinoNew: domain_web + "list-game-new",
  api_gamecasinoHot: domain_web + "list-game-hot",
  api_liveprocess: domain_web + "livelunch",
  api_listcashinout: domain_web + "cash/list",
  api_listbetting: domain_web + "list-betting",
  api_listgameSlot: domain_web + "list-game-detail?code=",
  api_listSend: domain_web + "send/list-send",
  api_Setting: domain_web + "setting",
  api_restInfo: domain_web + "info-casino",
  api_deleteNotify: domain_web + "notifacation/delete-notifacation",
  api_listFaq: domain_web + "faq/list-faq",
  api_listFaq_login: domain_web + "listfaqlogin",
  api_viewnotify: domain_web + "notifacation/view",
  api_delSen: domain_web + "send/delete-send",
  api_viewSend: domain_web + "send/view",
  api_viewChat: domain_web + "chat/view",
  api_viewall: domain_web + "send/view-all-send",
  api_deleteall: domain_web + "send/delete-all-send",
};

for (const [key, val] of Object.entries(globalVars)) {
  app.config.globalProperties[key] = val;
}

export const globalProperties = app.config.globalProperties;

app.use(store);
app.mount("#app");
