<script setup>
import { onBeforeMount, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const list = ref([]);

onBeforeMount(() => {
  if (proxy.token) {
    const token_user = window.localStorage.getItem("token");
    proxy.axios
      .get(proxy.api_listnotifi, {
        headers: {
          Authorization: "Bearer " + token_user,
        },
      })
      .then((response) => {
        list.value = response.data.data;
      });
  } else {
    proxy.axios.get(proxy.api_listnotifilist, {}).then((response) => {
      list.value = response.data.data;
      //**test
      list.value.push({ title: "Test notice" });
    });
  }
});
</script>

<template>
  <div class="col-lg-5">
    <div class="wptb-heading">
      <div class="row">
        <div class="col-md-12">
          <div class="wptb-item--inner">
            <h6 class="wptb-item--subtitle">NOTICE</h6>
            <h1 class="wptb-item--title">
              {{ $store.getters.langweb("fe.notification") || "Latest Notice" }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="place_order">
      <ul>
        <li
          class="totalvalue"
          v-for="(notice, index) in list.slice(0, 5)"
          :key="index"
        >
          <button
            @click="$store.dispatch('showHomePopup', { open: true, tab: 5 })"
          >
            <span class="text">{{ notice.title.substring(0, 25) }}</span>
            <span class="value">{{ notice.date }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="wptb-item--button" style="padding-top: 50px" v-if="false">
      <button
        class="btn"
        style="background-color: transparent; padding-left: 0px"
        @click="$store.dispatch('showHomePopup', { open: true, tab: 5 })"
      >
        <span class="btn-readmore--text"
          >View More NOTICE <i class="bi bi-chevron-double-right"></i
        ></span>
      </button>
    </div>
  </div>
</template>

<style scoped></style>
