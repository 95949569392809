<script setup>
import { getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const user = computed(() => store.state.user);

const logout = (e) => {
  e.preventDefault();
  proxy.axios
    .post(
      proxy.api_logout,
      {},
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (res.data.success === true) {
        localStorage.clear();
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      }
    });
};
</script>

<template>
  <!-- Top Bar -->
  <div class="header-top">
    <div class="container-fluid pe-4">
      <div
        class="header-top--inner d-flex justify-content-between align-items-center flex-wrap"
      >
        <!-- Left Box -->
        <div class="left-box d-none d-lg-flex align-items-center">
          <ul class="info-list">
            <li>
              <a href="#"
                ><span class="icon bi bi-star-fill"></span
                ><span style="color: #98741a">{{ user.name }}様</span>
                始まりました！</a
              >
            </li>
          </ul>
        </div>

        <!-- Right Box -->
        <div class="right-box d-flex align-items-center">
          <!-- Social Box -->
          <div class="info-list">
            <ul class="p-0 m-0">
              <li>
                <a
                  href="javascript:void(0)"
                  @click="
                    $store.dispatch('showHomePopup', { open: true, tab: 1 })
                  "
                >
                  <span class="icon bi bi-cash"></span>
                  {{ $store.getters.langweb("fe.amount") }}:
                  <strong>{{
                    new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                    }).format(user.totalAmount)
                  }}</strong>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="
                    store.dispatch('showHomePopup', { open: true, tab: 8 })
                  "
                >
                  <span class="icon bi bi-currency-yen"></span>
                  {{ $store.getters.langweb("fe.point") }}
                  <strong>{{
                    new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                    }).format(user.point)
                  }}</strong>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="logout">
                  <span class="icon bi bi-arrow-right-square-fill"></span>
                  <span style="color: #d70006; font-weight: 700">
                    ログアウト
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
