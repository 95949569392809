<script setup>
import { computed, watch, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import $ from "jquery";

const store = useStore();
const listGaming = computed(() => store.state.allSlotGameList);

watch(listGaming, (newValue) => {
  itemgamepp.value = [...newValue];
  paginatedDatalt();
});

const tabItemGame = ref("taba");
const itemgamepp = ref([]);
const listGamingsearch = ref([]);
const pagelt = ref(1);
const perPagelt = ref(35);
const datalt = ref([]);
const search = ref("");

function changeTabItemGame(tab) {
  tabItemGame.value = tab;
}

function searchGame(e) {
  e.preventDefault();
  let act_length = listGaming.value.length;
  let dataArray = [];
  let set = 0;
  $.each(listGaming.value, function (i) {
    if (i + 1 === act_length) {
      set = 1;
    }
    if (
      this.title.indexOf(search.value) !== -1 ||
      this.code.indexOf(search.value) !== -1
    ) {
      return dataArray.push(this);
    }
    if (set === 1) {
      // console.log("action: ", dataArray);
      // return dataArray
      // this.listGamingsearch = dataArray
      // totalCountlt.value = itemgamepp.value.length
      // this.paginatedDatalt()
      // datalt.value = dataArray
      // console.log('action: ', this.listGamingsearch);
    }
  });
  itemgamepp.value = dataArray;
  paginatedDatalt();
  // this.listGamingsearch = dataArray
  // console.log('dataArray',dataArray);
}

function paginatedDatalt() {
  // console.log('itemgamepp.value',itemgamepp.value);
  datalt.value = itemgamepp.value.slice(
    (pagelt.value - 1) * perPagelt.value,
    pagelt.value * perPagelt.value
  );
}

function clickCallbacklt(pageNum) {
  pagelt.value = pageNum;
  paginatedDatalt();
}

function runGame(item) {
  store.dispatch("getGameProcess", {
    api: item.vender,
    code: item.code,
    type: 2,
  });
  store.dispatch("showItemGamePopup", { open: false });
}

function close() {
  $("html").css("overflow", "auto");
  store.dispatch("showItemGamePopup", { open: false });
}

onUpdated(() => {
  $("html").css("overflow", store.state.itemGamePopup ? "hidden" : "auto");
});
</script>

<template>
  <div class="root-cash popupItemGame" v-if="store.state.itemGamePopup">
    <div class="wraper-cash">
      <div class="cash-tab">
        <div class="tabcash">
          <div class="titmenu_popup">
            {{ store.getters.langweb("fe.text1") }}
            <img
              src="../../../assets/images/right.svg"
              alt=""
              style="
                filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
                  saturate(6118%) hue-rotate(358deg) brightness(60%)
                  contrast(118%);
              "
            />
          </div>
          <ul>
            <li>
              <form action="" method="" name="frm_search" id="_frm_search">
                <div class="input_search">
                  <input
                    type="text"
                    v-model="search"
                    class="key_search"
                    placeholder="Search"
                    @keyup="searchGame"
                  />
                </div>
              </form>
            </li>
            <li
              v-bind:class="{
                active: tabItemGame === 'taba',
              }"
              v-on:click="changeTabItemGame('taba')"
            >
              <a class="text-center">
                <font-awesome-icon icon="fa-solid fa-clover" />&nbsp;
                {{ store.getters.langweb("fe.text3") }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="root-content-cash-tab sm:h-60vh relative overflow-x-hidden overflow-y-auto"
        >
          <div class="content-tab-cashin h-100">
            <div
              class="taba h-100"
              :style="$isMobile() ? '' : 'padding-top: 80px'"
              v-if="tabItemGame === 'taba'"
            >
              <div
                :class="
                  [
                    'text_category',
                    $isMobile()
                      ? 'absolute mx-auto my-2 left-1/2 sm:translate-y-0'
                      : 'absolute mx-auto my-2 left-1/2 -translate-x-1/2 sm:translate-y-0',
                  ].join(' ')
                "
              >
                <div>
                  <font-awesome-icon icon="fa-solid fa-clover" />&nbsp;
                  <span class="namegaming"></span>
                </div>
              </div>
              <div class="wrapper_tab_game">
                <div
                  class="grid grid-cols-6 sm:grid-cols-3 xs:grid-cols-2"
                  :style="$isMobile() ? 'overflow: auto;height: 46vh;' : ''"
                >
                  <a
                    href="javascript:void(0);"
                    @click="() => runGame(item)"
                    class="itemgamePP"
                    v-for="(item, index) in datalt"
                    v-bind:key="index"
                  >
                    <div class="wp-itemgpp">
                      <img class="g-img" :src="item.image" />
                      <div class="nameitem_game">
                        <span>{{ item.title }}</span>
                      </div>
                    </div>
                  </a>
                </div>
                <paginate
                  class="container_paginater"
                  @update:modelValue="clickCallbacklt"
                  :totalCount="itemgamepp.length"
                  :limit="perPagelt"
                  v-model="pagelt"
                ></paginate>
              </div>
            </div>
            <div class="tabb" v-if="tabItemGame === 'tabb'">
              <div class="text_category">
                <div>
                  <font-awesome-icon icon="fa-solid fa-bomb" />&nbsp;
                  {{ store.getters.langweb("fe.text4") }}
                </div>
              </div>
              <div class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game">
                {{ listGamingsearch }}
                <div
                  class="card"
                  v-for="(post, index) in listGamingsearch"
                  :key="index"
                >
                  <a>
                    {{ post.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="close-subpage" @click="close">x</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../common.css";
@import "./ItemGamePopup.css";
</style>
