<script setup>
import { getCurrentInstance, ref } from "vue";

const { proxy } = getCurrentInstance();
const username_lg = ref("");
const password_lg = ref("");

const login = (e) => {
  e.preventDefault();
  proxy.axios
    .post(proxy.api_login, {
      username: username_lg.value,
      password: password_lg.value,
    })
    .then((res) => {
      if (res.data.success === true) {
        window.localStorage.setItem("token", res.data.data.token);
        window.localStorage.setItem("level", res.data.data.user.level);
        window.localStorage.setItem("refcode", res.data.data.user.refcode);
        window.localStorage.setItem("offline", res.data.data.user.offline);
        let today = new Date();
        let date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        let time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        let timestamp = date + " " + time;
        window.localStorage.setItem("time_token", timestamp);
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      } else {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 404) {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: e.response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
};
</script>

<template>
  <div class="wptb-header--cart mr-3" v-if="!$store.state.loggedIn">
    <div class="wptb-cart-box shogun-login">
      <div class="wptb-grand-total">
        <ul>
          <li>
            {{ $store.getters.langweb("fe.login") }}
          </li>
          <li>
            <form method="post" name="LoginFrm" @submit="(e) => login(e)">
              <div class="wptb-form--inner">
                <div class="row">
                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="username_lg"
                        v-model="username_lg"
                        :placeholder="$store.getters.langweb('fe.username')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 mb-4">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        name="password_lg"
                        v-model="password_lg"
                        :placeholder="$store.getters.langweb('fe.passwords')"
                        style="border-color: #3d3d3d"
                        @click="(evt) => evt.stopPropagation()"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12">
                    <div class="form-group" v-if="false">
                      <input type="checkbox" name="remmeber" />
                      <label for="remmeber" class="text-capitalize">
                        パスワードを覚える
                      </label>
                    </div>

                    <div class="wptb-item--button">
                      <button class="btn-two white" type="submit">
                        <span class="btn-wrap">
                          <span class="text-first">
                            {{ $store.getters.langweb("fe.login") }}
                          </span>
                          <span class="text-second">
                            <i class="bi bi-plus"></i>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
        <div class="wptb-item--button" v-if="false">
          <a
            href="javascript:void(0)"
            class="btn-three"
            style="height: 60px; font-size: var(--fs-16)"
          >
            <div class="btn-wrap">
              <span class="text-first">{{
                $store.getters.langweb("fe.registers")
              }}</span>
              <span class="text-second">{{
                $store.getters.langweb("fe.registers")
              }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
