<script setup>
import $ from "jquery";
import CashInOut from "@/components/v1/home/CashInOut.vue";
import ItemGamePopup from "@/components/v2/popup/ItemGamePopup.vue";
import HomePopup from "@/components/v2/popup/HomePopup.vue";
import NoticeBoard from "@/components/v2/board/NoticeBoard.vue";
import FaqBoard from "@/components/v2/board/FaqBoard.vue";
import MainSlider from "@/components/v2/home/MainSlider.vue";
import GameList from "@/components/v2/home/GameList.vue";
import { onOpenSports } from "@/components/v2/header/common";

$(document).ready(function () {
  $(document).on("click", ".notifyhome_click", function () {
    $(this).parent().find(".substring_content_tbs").show();
    $(".toggle-section").css("z-index", "-1");
    $(".footer-section").css("z-index", "-1");
    // $("body").css("overflow", "hidden");
  });
  $(document).on("click", ".close_notis", function () {
    $(this).parents(".substring_content_tbs").hide();
    $(".toggle-section").css("z-index", "0");
    $(".footer-section").css("z-index", "0");
    $("body").css("overflow", "initial");
  });
  $(document).on("click", ".notifyhome_clicks", function () {
    $(this).parent().find(".substring_content_tbss").show();
    $(".toggle-section").css("z-index", "-1");
    $(".footer-section").css("z-index", "-1");
    // $("body").css("overflow", "hidden");
  });
  $(document).on("click", ".close_notiss", function () {
    $(this).parents(".substring_content_tbss").hide();
    $(".toggle-section").css("z-index", "0");
    $(".footer-section").css("z-index", "0");
    // $("body").css("overflow", "initial");
  });
  $(document).on("click", ".more-btn", function () {
    $(".subpage-content .subpagecontent-header").text(
      `${$(this).siblings().text()}`
    );
  });
});
</script>
<template>
  <HomePopup />
  <ItemGamePopup />
  <div class="wraper-root">
    <main class="wrapper" id="games-section">
      <!-- Slider Section -->
      <MainSlider />

      <!-- Casino Grid -->
      <GameList
        id="casino-section"
        title="ライブカジノ"
        sub-title="SHOGUN CASINO"
        list-name="casinoList"
        :type="1"
      />

      <!-- Slot Grid -->
      <GameList
        id="slotgame-section"
        title="スロット"
        sub-title="SHOGUN SLOT"
        list-name="slotGameList"
        :type="2"
      />

      <!-- FAQ, Notice -->
      <section class="wptb-faq-one pd-top-more p-0 pt-5">
        <div class="container">
          <div class="wptb-faq--inner">
            <div class="row">
              <FaqBoard />
              <NoticeBoard />
            </div>
          </div>
        </div>
      </section>

      <!-- footer banner-->
      <div class="wptb-funfacts-one">
        <div class="container">
          <div class="wptb-image-single mb-4 d-inline-block wow fadeInUp">
            <div class="wptb-item--inner">
              <button class="wptb-item--image" @click="onOpenSports($store)">
                <img src="/assets/img/more/banner_sports.png" alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <input type="hidden" name="datacode" class="datacode" value="0" />
      <section class="board-section w-ba" v-if="false">
        <div class="container max-width-gib dflex-ac-jc">
          <CashInOut v-if="false" />
        </div>
      </section>
    </main>
  </div>
</template>
<style scoped></style>
