<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import NotLoggedInBar from "./NotLoggedInBar.vue";
import LoggedInBar from "./LoggedInBar.vue";

const store = useStore();
const loggedIn = computed(() => store.state.loggedIn);
</script>

<template>
  <LoggedInBar v-if="loggedIn" />
  <NotLoggedInBar v-else />
</template>

<style scoped></style>
